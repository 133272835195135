<!-- 购买vip数据 -->
<template>
  <div style="padding: 20px;display: flex;flex-direction: column;height: 85vh;box-sizing: border-box;overflow: auto;">
    <div style="font-size: 26px;font-weight: bold;margin-bottom: 10px;">购买vip数据</div>
    <div
      style="background: #fff;display: flex;padding: 20px;box-sizing: border-box;align-items: center;justify-content: space-between;">
      <div style="display: flex;align-items: center;">
        <div style="color: #3D3D3D;font-size: 14px;margin-left: 10px;">品牌:</div>
        <el-select v-model="brandId" placeholder="品牌" class="cinput ac" @change="getList" :disabled="isDisabled">
          <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-date-picker v-model="date" type="month" placeholder="选择月" value-format="yyyy-MM" style="margin-left:10px"
          @change="getList">
        </el-date-picker>
      </div>
      <div style="display: flex;align-items: center;">
        <div>代理商总数:{{ tableData.length }}</div>
        <div style="margin:0 20px">购买vip总数:{{ agentNumber }}</div>
        <div>
          <el-button icon="el-icon-download" style="color: #00A78E;border-color: #00A78E;margin-left: 10px;"
            v-if="tableData.length > 0" @click="exportExcel">导出</el-button>
        </div>
      </div>
    </div>
    <el-table :header-cell-style="{ background: '#F7F7F7' }" id="out-table" :data="tableData"
      style="width: 100%; padding: 20px;overflow: auto;" v-loading="loading" element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading">
      <el-table-column prop="agentName" align="center" label="代理商"></el-table-column>

      <el-table-column prop="expireNotBuyAgainCount" align="center" label="已到期未续费"></el-table-column>
      <el-table-column prop="buyAgainCount" align="center" label="复购"></el-table-column>
      <el-table-column prop="buyAgainCount" align="center" label="新购买的">
        <template slot-scope="scope">
          <div>
            {{ scope.row.buyVipCount ? scope.row.buyVipCount - (scope.row.buyAgainCount ? scope.row.buyAgainCount : 0) : 0
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="buyVipCount" align="center" label="购买vip数量(包含复购的)"></el-table-column>
      <el-table-column prop="studyTimeHour" align="center" label="总学习时长/h"></el-table-column>
      <el-table-column prop="teacherName" align="center" label="操作" width="200">
        <template slot-scope="scope">
          <div style="display: flex; justify-content: center">
            <el-link type="primary" :underline="false" @click='repurchaseBtn(scope.row)'>复购</el-link>
            <el-link type="primary" :underline="false" style="margin-left: 10px;" @click='buynew(scope.row)'>新购买</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="'总数'+total" :visible.sync="dialogVisible" width="60%" top="0" style="overflow: auto;" class="buyvip"  >
      <el-button icon="el-icon-download" style="color: #00A78E;border-color: #00A78E;float: right;margin-bottom: 8px;"
            v-if="mybuyList.length > 0" @click="exportExcel2">导出</el-button>
      <el-table :header-cell-style="{ background: '#F7F7F7' }" id="out-table2" :data="mybuyList"
        style="width: 100%; overflow: auto;" v-loading="loading2" element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading">
        <el-table-column prop="name" align="center" label="用户名"></el-table-column>
        <el-table-column prop="phone" align="center" label="联系方式"></el-table-column>
        <el-table-column prop="brandName" align="center" label="品牌"></el-table-column>
        <el-table-column prop="agentName" align="center" label="代理商"></el-table-column>
        <el-table-column prop="storeName" align="center" label="门店名"></el-table-column>
      </el-table>
    </el-dialog>


  </div>
</template>

<script>
import FileSaver from "file-saver";
import * as XLSX from 'xlsx'
import { buyVipDataMethod, buysList } from '../apis/index'
export default {
  data() {
    return {
      dialogVisible: false,
      loading: true,
      loading2: true,
      brandId: '',
      date: '',
      tableData: [],
      isDisabled: true,
      currentD: '',
      lastcurrentD: '',
      startTime: '',
      endTime: '',
      mybuyList:[],
      total:0
    }
  },
  computed: {
    agentNumber() {
      let num = 0
      this.tableData.map(item => Number(num += (item.buyVipCount)))
      return num
    },
  },
  created() {
    let brandId = Number(this.$store.state.brandId)
    brandId != 'undefined' && brandId ? this.brandId = brandId : this.brandId = '1'
    brandId != 'undefined' && brandId ? this.isDisabled = true : this.isDisabled = false
    this.brandList = this.$store.state.brandList
    this.getList()
  },
  methods: {
    repurchaseBtn(row) {//复购
      this.getDateTime()
      const params = {
        agentUserId: row.agentId,
        brandId: this.brandId,
        // oldBuyTimeMax: this.date ? this.endTime : this.currentD,
        oldBuyTimeMax: this.currentD,
        oldBuyTimeMin: '2022-05-20 00:00:00',
        recentBuyTimeMax: this.date ? this.endTime : '',
        recentBuyTimeMin: this.date ? this.startTime : '',
        onlyRecent: 0,
        pageIndex:1,
        pageSize:999999
      }
      this.dialogVisible=true
      this.buyMethod(params)
      // console.log(params)
    },
    buynew(row) {//新购买
      this.getDateTime()
      const params = {
        agentUserId: row.agentId,
        brandId: this.brandId,
        recentBuyTimeMax: this.date ? this.endTime : this.currentD,
        recentBuyTimeMin: this.date ? this.startTime : this.lastcurrentD,
        onlyRecent: 1,
        pageIndex:1,
        pageSize:999999
      }
      this.dialogVisible=true
      this.buyMethod(params)
      // console.log(params)
    },
    async buyMethod(params) {
      this.loading2 = true
      const { data } = await buysList(params)
      if(data.code==200){
        this.loading2 = false
        this.mybuyList=data.data?data.data.records:[]
        this.total=data.data?data.data.total:0
      }else{
        this.$message.error(data.msg)
      }
    },


    getDateTime() {//获取关键时间
      const now = new Date();
      const year = now.getFullYear(); // 年
      const month = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1 // 月
      const date = now.getDate() < 10 ? '0' + now.getDate() : now.getDate() // 日
      var hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours() // 时
      var minutes = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes() // 分
      var seconds = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes() //秒
      const currentD = `${year}-${month}-${date} ${hour}:${minutes}:${seconds}`//当前时间

      const lastnow = new Date(now.setFullYear(now.getFullYear() - 1))
      const lastyear = lastnow.getFullYear(); // 年
      const lastmonth = lastnow.getMonth() + 1 < 10 ? '0' + (lastnow.getMonth() + 1) : lastnow.getMonth() + 1 // 月
      const lastdate = lastnow.getDate() < 10 ? '0' + lastnow.getDate() : lastnow.getDate() // 日
      const lastcurrentD = `${lastyear}-${lastmonth}-${lastdate} ${hour}:${minutes}:${seconds}`//去年今日
      var startTime = ''
      var endTime = ''
      if (this.date) {
        const selectnow = new Date(this.date)
        const yearstart = selectnow.getFullYear(); // 年
        const monthstart = selectnow.getMonth() + 1 < 10 ? '0' + (selectnow.getMonth() + 1) : selectnow.getMonth() + 1 // 月
        const datestart = '01'
        const dateend = new Date(yearstart, monthstart, 0).getDate()
        startTime = `${yearstart}-${monthstart}-${datestart} 00:00:00`//当月开始时间
        endTime = `${yearstart}-${monthstart}-${dateend} 24:00:00`//当月结束时间
      } else {
        startTime = ''
        endTime = ''
      }
      this.currentD = currentD
      this.lastcurrentD = lastcurrentD
      this.startTime = startTime
      this.endTime = endTime
    },
    async getList() {
      this.loading = true
      const parmas = {
        brandId: this.brandId,
        year: this.date ? this.date.substring(0, 4) : '',
        month: this.date ? this.date.substring(5, 7) : '',
      }
      try {
        const { data } = await buyVipDataMethod(parmas)
        if (data.code == 200) {
          console.log(data.data)
          this.tableData = data.data
          this.loading = false
        } else {
          this.$message.error(data.msg)
        }
      } catch (error) {
        console.log(error)
        this.$message.error('请求异常')
      }

    },
    exportExcel() {//导出表格
      /* 从表生成工作簿对象 */
      let wb = XLSX.utils.table_to_book(document.querySelector('#out-table'))
      /* 获取二进制字符串作为输出 */
      var wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        FileSaver.saveAs(
          //Blob 对象表示一个不可变、原始数据的类文件对象。
          //Blob 表示的不一定是JavaScript原生格式的数据。
          //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
          //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
          new Blob([wbout], { type: 'application/octet-stream' }),
          //设置导出文件名称
          '学员列表.xlsx'
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      }
      return wbout


    },
    exportExcel2() {//导出表格
      /* 从表生成工作簿对象 */
      let wb = XLSX.utils.table_to_book(document.querySelector('#out-table2'))
      /* 获取二进制字符串作为输出 */
      var wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        FileSaver.saveAs(
          //Blob 对象表示一个不可变、原始数据的类文件对象。
          //Blob 表示的不一定是JavaScript原生格式的数据。
          //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
          //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
          new Blob([wbout], { type: 'application/octet-stream' }),
          //设置导出文件名称
          '数据列表.xlsx'
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      }
      return wbout


    },
  }
}
</script>

<style lang='less' scoped>
.el-dialog__wrapper.buyvip{
  width: 100%;
  height: 100%;
}
  /deep/.el-dialog{
    position: absolute ;
    width: 50% ;
    height: 80% ;
    overflow: auto ;
    left: 50% ;
    top: 50% ;
    transform: translate(-50%, -50%) ;
    margin-top:0 
  }
</style>
